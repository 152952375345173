import actions from '../constants/actions';

export function getProducts(payload) {
  return {
    type: actions.GET_PRODUCTS,
    payload,
  };
}

export function sendEmail(payload) {
  return {
    type: actions.SEND_EMAIL,
    payload,
  };
}

export function confirmOrderToClover(payload) {
  return {
    type: actions.CONFIRM_ORDERS_TO_CLOVER,
    payload,
  };
}

export function paypalStatus(payload) {
  return {
    type: actions.UPDATE_PAY_PAL_STATUS,
    payload,
  };
}

export function updateCart(payload) {
  return {
    type: actions.UPDATE_CART,
    payload,
  };
}

export function resetCart() {
  return {
    type: actions.RESET_CART,
  };
}
export function resetContactUs() {
  return {
    type: actions.RESET_CONTACT_US,
  };
}

export function updateTotals(payload) {
  return {
    type: actions.UPDATE_CART_TOTALS,
    payload,
  };
}
