import { all, fork } from 'redux-saga/effects';
import * as appWatcher from '../watchers/index';

function* appSaga() {
  yield all([
    fork(appWatcher.getProductsWatcher),
    fork(appWatcher.confirmOrderToClover),
    fork(appWatcher.sendEmail),
  ]);
}

export default appSaga;
