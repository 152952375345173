import { combineReducers } from 'redux';
import Products from './products-reducer';
import addToCart from './add-to-cart-reducer';
import ConfirmOrderToClover from './confirm-order-to-clover';
import sendEmailReducer from './send-email-reducer';

const rootReducer = combineReducers({
  products: Products,
  cart: addToCart,
  confirmOrder: ConfirmOrderToClover,
  sendEmailStatus: sendEmailReducer,
});

export default rootReducer;
