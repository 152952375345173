import actions from '../constants/actions';

const initialState = {
  fetching: false,
  error: null,
  result: null,
};

export default function sendEmailReducer(state = initialState, action) {
  const { type, result, error } = action;
  switch (type) {
    case actions.SEND_EMAIL:
      return {
        ...state,
        fetching: true,
      };
    case actions.SEND_EMAIL_SUCCESS:
      return {
        fetching: false,
        result,
        error: null,
      };
    case actions.SEND_EMAIL_ERROR:
      return {
        fetching: false,
        error: error,
        result: null,
      };
    case actions.RESET_CONTACT_US:
      return initialState;
    default:
      return state;
  }
}
