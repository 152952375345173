import React from 'react';
import ReactDOM from 'react-dom';

import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleWare from 'redux-saga';
import { createLogger } from 'redux-logger';
//import identity from 'lodash/identity';
import { BrowserRouter as Router } from 'react-router-dom';

import appReducers from './reducers/index';
import appSaga from './sagas/index';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';

//import TagManager from 'react-gtm-module';

const sagaMiddleware = createSagaMiddleWare();
const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production',
});

/* deve tools
const createStoreWithMiddleware = compose(
  applyMiddleware(sagaMiddleware, loggerMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : identity
)(createStore);

*/

const createStoreWithMiddleware = compose(
  applyMiddleware(sagaMiddleware, loggerMiddleware)
)(createStore);

export const store = createStoreWithMiddleware(appReducers, {});

sagaMiddleware.run(appSaga);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more order-online service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

/* const tagManagerArgs = {
  gtmId: 'GTM-WF2SJ69',
};

TagManager.initialize(tagManagerArgs);
 */
