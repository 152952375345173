const actions = {
  ADD_TODO: 'ADD_TODO',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_ERROR: 'GET_PRODUCTS_ERROR',
  SEND_EMAIL: 'SEND_EMAIL',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_ERROR: 'SEND_EMAIL_ERROR',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR: 'GET_CATEGORIES_ERROR',
  CONFIRM_ORDERS_TO_CLOVER: 'CONFIRM_ORDERS_TO_CLOVER',
  CONFIRM_ORDERS_TO_CLOVER_SUCCESS: 'CONFIRM_ORDERS_TO_CLOVER_SUCCESS',
  CONFIRM_ORDERS_TO_CLOVER_ERROR: 'CONFIRM_ORDERS_TO_CLOVER_ERROR',
  UPDATE_CART: 'UPDATE_CART',
  RESET_CART: 'RESET_CART',
  RESET_CONTACT_US: 'RESET_CONTACT_US',
  UPDATE_CART_TOTALS: 'UPDATE_CART_TOTALS',
  UPDATE_PAY_PAL_STATUS: 'UPDATE_PAY_PAL_STATUS',
};

export default actions;
