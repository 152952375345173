import actions from '../constants/actions';

const initialState = {
  fetching: false,
  error: null,
  result: null,
  categoriesAndProducts: null,
  cateringProducts: null,
  options: [
    {
      value: 'all',
      label: 'All',
    },
  ],
};

const constructCategories = (data) => {
  const categories = constructProducts(data);

  let categoriesAndProducts = [];

  categories.forEach(({ label }) => {
    categoriesAndProducts.push({
      header: label,
      name: label,
      body: data.filter((o) => o.category === label),
    });
  });

  return categoriesAndProducts;
};

const constructProducts = (data) => {
  let allOptions = [];
  data.forEach(({ category }) => {
    allOptions.push(category);
  });

  allOptions = [...new Set(allOptions)];
  const resultOptions = [];
  allOptions.forEach((op) => {
    resultOptions.push({
      value: op,
      label: op,
    });
  });

  return resultOptions;
};

const formatProducts = (data) => {
  const products = [];
  data &&
    data.forEach((o) => {
      products.push({
        ...o,
        desc: o.desc,
        category: o.category,
      });
    });

  return products;
};

export default function getProductsReducer(state = initialState, action) {
  const { type, result, error } = action;
  switch (type) {
    case actions.GET_PRODUCTS:
      return {
        ...state,
        fetching: true,
      };
    case actions.GET_PRODUCTS_SUCCESS:
      const activeProducts = result.filter((o) => o.itemStatus === 1);
      return {
        ...state,
        fetching: false,
        result: formatProducts(activeProducts),
        allMenuItems: formatProducts(activeProducts).filter(
          (o) => o.isCateringAvailable === false
        ),
        options: [...state.options, ...constructProducts(activeProducts)],
        categoriesAndProducts: constructCategories(
          activeProducts.filter((o) => o.isCateringAvailable === false)
        ),
        specialityProducts: activeProducts
          .filter((o) => o.isCateringAvailable === false)
          .slice(0, 6),
        cateringProducts: activeProducts.filter(
          (o) => o.isCateringAvailable === true
        ),
        error: null,
      };
    case actions.GET_PRODUCTS_ERROR:
      return {
        ...state,
        fetching: false,
        error: error,
        result: null,
      };
    default:
      return state;
  }
}
