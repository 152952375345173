import actions from '../constants/actions';

const initialState = {
  result: [],
  cartTotalNumber: 0,
  invoiceTip: 0,
  invoiceTaxes: 0,
  invoiceSubtotal: 0,
};

const checkItemAndUpdateList = (result, newItem) => {
  const newResult = [];

  if (result.length === 0) {
    newResult.push(newItem);
    return newResult;
  }

  if (!checkIfItemExists(result, newItem, newItem.isSpiceSelection)) {
    newResult.push(newItem);
    return [...result, ...newResult];
  }

  if (newItem.quantity === 0) {
    let derivedResult = [];
    result.forEach((o) => {
      if (o.name === newItem.name) {
        if (newItem.isSpiceSelection && newItem.spiceLevel === o.spiceLevel) {
        } else if (!newItem.isSpiceSelection) {
        } else {
          derivedResult.push(o);
        }
      } else {
        derivedResult.push(o);
      }
    });

    return derivedResult;
  }

  result.forEach((oldCartItems) => {
    let q = oldCartItems.quantity;
    if (oldCartItems.name === newItem.name) {
      if (newItem.addedFromOrderPage) {
        if (newItem.isSpiceSelection) {
          if (oldCartItems.spiceLevel === newItem.spiceLevel) {
            q = oldCartItems.quantity + newItem.quantity;
          }
        } else {
          q = oldCartItems.quantity + newItem.quantity;
        }
      } else if (oldCartItems.quantity < newItem.quantity) {
        if (
          newItem.isSpiceSelection &&
          oldCartItems.spiceLevel === newItem.spiceLevel
        ) {
          q = oldCartItems.quantity + 1;
        } else if (!newItem.isSpiceSelection) {
          q = oldCartItems.quantity + 1;
        }
      } else if (oldCartItems.quantity > newItem.quantity) {
        if (
          newItem.isSpiceSelection &&
          oldCartItems.spiceLevel === newItem.spiceLevel
        ) {
          q = oldCartItems.quantity - 1;
        } else if (!newItem.isSpiceSelection) {
          q = oldCartItems.quantity - 1;
        }
      }
      delete oldCartItems.addedFromOrderPage;
      newResult.push({
        ...oldCartItems,
        quantity: q,
      });
    } else if (newItem.quantity !== 0) {
      delete oldCartItems.addedFromOrderPage;
      newResult.push(oldCartItems);
    }
  });

  return newResult;
};

const checkIfItemExists = (array, item, flag) =>
  array.find((obj) => {
    if (flag) {
      return obj.name === item.name && obj.spiceLevel === item.spiceLevel;
    } else {
      return obj.name === item.name;
    }
  });

const checkCateringCart = (data, newItem) => {
  const oldResult = data;
  const newResult = [];
  const onlyCateringItems = data.filter(
    (o) => o.orderFromCatering && o.orderFromCatering === true
  );
  const otherCartItems = oldResult.filter((o) => !o.orderFromCatering);

  if (onlyCateringItems.length === 0) {
    return [...otherCartItems, newItem];
  } else if (newItem.quantity === 0) {
    onlyCateringItems.forEach((o) => {
      if (o.name === newItem.name && o.price === newItem.price) {
      } else {
        newResult.push(o);
      }
    });
    return [...otherCartItems, ...newResult];
  } else if (
    !onlyCateringItems.find(
      (o) => o.name === newItem.name && o.price === newItem.price
    )
  ) {
    return [...otherCartItems, ...onlyCateringItems, newItem];
  } else {
    onlyCateringItems.forEach((o) => {
      if (o.name === newItem.name && o.price === newItem.price) {
        if (newItem.isSpiceSelection && o.spiceLevel === newItem.spiceLevel) {
          if (newItem.orderFromCatering) {
            newResult.push({
              ...o,
              quantity: o.quantity + newItem.quantity,
            });
          } else {
            if (o.quantity === newItem.quantity + 1) {
              newResult.push({
                ...o,
                quantity: o.quantity - 1,
              });
            } else if (o.quantity + 1 === newItem.quantity) {
              newResult.push({
                ...o,
                quantity: o.quantity + 1,
              });
            } else {
              newResult.push({
                ...o,
                quantity: o.quantity + newItem.quantity,
              });
            }
          }
        } else {
          if (o.quantity === newItem.quantity + 1) {
            newResult.push({
              ...o,
              quantity: o.quantity - 1,
            });
          } else if (o.quantity + 1 === newItem.quantity) {
            newResult.push({
              ...o,
              quantity: o.quantity + 1,
            });
          } else {
            newResult.push({
              ...o,
              quantity: o.quantity + newItem.quantity,
            });
          }
        }
      } else {
        newResult.push(o);
      }
    });
  }

  return [...otherCartItems, ...newResult];
};

export default function addToCart(state = initialState, action) {
  const { type, payload } = action;

  if (type === actions.UPDATE_CART) {
    const newResult = !payload.orderFromCatering
      ? checkItemAndUpdateList(state.result, payload)
      : checkCateringCart(state.result, payload);
    let newCount = 0;
    if (newResult.length > 0) {
      newResult.forEach((item) => (newCount = newCount + item.quantity));
    }
    return {
      ...state,
      cartTotalNumber: newCount,
      result: newResult,
    };
  } else if (type === actions.UPDATE_CART_TOTALS) {
    return { ...state, ...payload };
  } else if (type === actions.RESET_CART) {
    return initialState;
  } else {
    return state;
  }
}
