import React, { useEffect, Suspense, lazy, useContext, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.css';
import { getProducts } from './actions/index';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import features from './constants/feature-flags';
import Alert from './components/dialog-transition';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});

const FeatureFlags = React.createContext();

const Home = lazy(() => import('./components/home'));
const OrderOnline = lazy(() => import('./components/order-online'));
const Catering = lazy(() => import('./components/catering'));
const Contact = lazy(() => import('./components/contact'));
const Menu = lazy(() => import('./components/menu'));
const Checkout = lazy(() => import('./components/checkout'));
const AppBarWithFabButton = lazy(() => import('./components/app-bar'));
const ThankYou = lazy(() => import('./components/thank-you'));

const sunsetAppUrls = [
  '/order-online.php',
  '/contact.php',
  '/menu.php',
  '/catering.php',
];
function Loader() {
  return (
    <div className={'loader'}>
      <CircularProgress color="secondary" />
    </div>
  );
}

function App(props) {
  const { getProducts } = props;
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  debugger;

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  if (
    window.location.protocol === 'http:' &&
    window.location.hostname !== 'localhost'
  ) {
    const httpsUrl = `https://${window.location.host}`;
    window.location.replace(httpsUrl);
  }

  if (sunsetAppUrls.includes(window.location.pathname)) {
    window.location.replace(window.location.pathname.replace('.php', ''));
  }
  return (
    <FeatureFlags.Provider value={{ features, setIsAlertOpen }}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Suspense fallback={<Loader />}>
            <Switch>
              <AppBarWithFabButton>
                <Route exact path={'/order-online'} component={OrderOnline} />
                <Route exact path={'/contact'} component={Contact} />
                <Route exact path={'/catering'} component={Catering} />
                <Route exact path={'/menu'} component={Menu} />
                <Route exact path={'/checkout'} component={Checkout} />
                <Route exact path={'/thank-you'} component={ThankYou} />
                <Route exact path={'/'} component={Home} />
              </AppBarWithFabButton>
            </Switch>
          </Suspense>
        </div>
      </ThemeProvider>
      {isAlertOpen && (
        <Alert open={isAlertOpen} handleAlertClose={setIsAlertOpen} />
      )}
    </FeatureFlags.Provider>
  );
}

export const Features = () => useContext(FeatureFlags);

const mapDispatchToProps = {
  getProducts,
};

export default connect(null, mapDispatchToProps)(App);
