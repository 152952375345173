import { call, put } from 'redux-saga/effects';
import actions from '../constants/actions';

async function fetchApi(url, headers = {}) {
  let result;
  try {
    const response = await fetch(url, { ...headers });
    result = await response.json();
  } catch (e) {
    result = null;
  }

  return result;
}

export function* getProductsWorker() {
  const endpoint = '/api/products';
  try {
    const response = yield call(fetchApi, endpoint);
    //const respe = yield call(fetchApi, '/api/send-email', {method: 'POST'});
    yield put({
      type: actions.GET_PRODUCTS_SUCCESS,
      result: response,
      error: null,
    });
  } catch (e) {
    yield put({
      type: actions.GET_PRODUCTS_ERROR,
      result: null,
      error: 'error',
    });
  }
}

export function* sendEmailWorker({ payload }) {
  const { email, name, subject, message } = payload;
  const endpoint = `/api/send-email?email=${email}&name=${name}&subject=${subject}&message=${message}`;
  try {
    const response = yield call(fetchApi, endpoint);
    yield put({
      type: actions.SEND_EMAIL_SUCCESS,
      result: response,
      error: null,
    });
  } catch (e) {
    yield put({
      type: actions.SEND_EMAIL_ERROR,
      result: null,
      error: 'error',
    });
  }
}

export const confirmOrderToCloverWorker = function* ({ payload }) {
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    cartData,
    total,
    specialInstructions,
    pickupDateTime,
    tip,
    tax,
    couponCode,
  } = payload;
  const items =
    cartData &&
    cartData.map((o) => ({
      cloverCode: o.cloverCode,
      name: o.name,
      price: o.price,
      spiceLevel: o.spiceLevel,
      spiceLevelFlag: o.spiceLevelFlag,
      quantity: o.quantity,
    }));
  const endpoint = `/api/confirmOrder`;
  const timeStamp = new Date();
  const data = {
    timeStamp,
    total,
    email,
    specialInstructions,
    firstName,
    lastName,
    phoneNumber,
    pickupDateTime,
    items,
    tip,
    tax,
    couponCode,
  };

  try {
    const response = yield call(fetchApi, endpoint, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data),
    });

    const emailResponse = yield call(fetchApi, '/api/order-email', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (response && emailResponse) {
      yield put({
        type: actions.CONFIRM_ORDERS_TO_CLOVER_SUCCESS,
        result: response,
        error: null,
      });
    } else {
      throw new Error({ message: 'error while submitting order' });
    }
  } catch (e) {
    yield put({
      type: actions.CONFIRM_ORDERS_TO_CLOVER_ERROR,
      result: null,
      error: 'error',
    });
  }
};
