import actions from '../constants/actions';

const initialState = {
  fetching: false,
  error: null,
  result: null,
};

export default function confirmOrderToCloverReducer(
  state = initialState,
  action
) {
  const { type, result } = action;
  switch (type) {
    case actions.CONFIRM_ORDERS_TO_CLOVER:
      return {
        ...state,
        fetching: true,
      };
    case actions.CONFIRM_ORDERS_TO_CLOVER_SUCCESS:
      return {
        ...state,
        fetching: false,
        result: result.result,
        error: null,
      };
    case actions.CONFIRM_ORDERS_TO_CLOVER_ERROR:
      return {
        ...state,
        fetching: false,
        error: result.error,
        result: null,
      };
    default:
      return state;
  }
}
