import { takeLatest } from 'redux-saga/effects';
import actions from '../constants/actions';
import * as worker from '../workers';

export function* getProductsWatcher() {
  yield takeLatest(actions.GET_PRODUCTS, worker.getProductsWorker);
}

export function* confirmOrderToClover() {
  yield takeLatest(
    actions.CONFIRM_ORDERS_TO_CLOVER,
    worker.confirmOrderToCloverWorker
  );
}

export function* sendEmail() {
  yield takeLatest(actions.SEND_EMAIL, worker.sendEmailWorker);
}
